var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('banner'),_c('section',{staticClass:"container"},[_c('section',{staticStyle:{"margin-top":"50px"}},[_c('section-title',[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('home.browse.h'))+" "),_c('drop-down',{attrs:{"data":_vm.gameTypes,"primary":"","borderless":""},scopedSlots:_vm._u([{key:"all",fn:function(){return [_c('all-solid-svg',{staticClass:"mr-2"})]},proxy:true}]),model:{value:(_vm.gameType),callback:function ($$v) {_vm.gameType=$$v},expression:"gameType"}})],1)]),_c('div',{staticClass:"card-box mt-4"},[(_vm.gameType.value === 'all' || _vm.gameType.value === 'dota2')?_c('collection-card',{staticClass:"card-item",attrs:{"item":{
            name: 'DOTA2',
            title: 'DOTA2',
            image_url: 'dota2',
            avatar_url: 'dota2',
          },"to":"/collections/dota2"}}):_vm._e(),(_vm.gameType.value === 'all' || _vm.gameType.value === 'csgo')?_c('collection-card',{staticClass:"card-item",attrs:{"item":{
            name: 'CSGO',
            title: 'CSGO',
            image_url: 'csgo',
            avatar_url: 'csgo',
          },"to":"/collections/csgo"}}):_vm._e()],1)],1),_c('section',{staticStyle:{"margin-top":"50px"}},[_c('section-title',[_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('home.explore.h'))+" "),_c('drop-down-price-range',{staticClass:"ml-4",model:{value:(_vm.priceRange),callback:function ($$v) {_vm.priceRange=$$v},expression:"priceRange"}})],1),_c('drop-down',{staticClass:"ml-auto",attrs:{"data":_vm.options7},scopedSlots:_vm._u([{key:"title",fn:function(ref){
          var selected = ref.selected;
return [(_vm.priceSort.value === 'htl')?_c('sort-down-svg'):_c('sort-up-svg'),_vm._v(" "+_vm._s(selected.label)+" ")]}}]),model:{value:(_vm.priceSort),callback:function ($$v) {_vm.priceSort=$$v},expression:"priceSort"}})],1),_c('slide-chip-select-horizontal',{attrs:{"options":_vm.itemTypes,"label":"品质"},model:{value:(_vm.itemType),callback:function ($$v) {_vm.itemType=$$v},expression:"itemType"}}),_c('slide-chip-select-horizontal',{attrs:{"options":_vm.itemWears,"label":"外观"},model:{value:(_vm.itemWear),callback:function ($$v) {_vm.itemWear=$$v},expression:"itemWear"}}),(_vm.loading)?_c('div',{staticClass:"card-box mt-1"},_vm._l((8),function(i){return _c('div',{key:i,staticClass:"card-item",staticStyle:{"padding":"10px"}},[_c('b-skeleton',{staticClass:"m-0",staticStyle:{"border-radius":"32px"},attrs:{"width":"","height":"420px"}})],1)}),0):_c('div',{staticClass:"card-box mt-1"},[(_vm.filteredItems && _vm.filteredItems.length === 0)?_c('div',{staticClass:"d-flex justify-content-center align-items-center text-secondary w-full",staticStyle:{"height":"300px"}},[_vm._v(" 没有找到相关装备~ ")]):_vm._e(),_vm._l((_vm.filteredItems),function(i,k){return _c('collection-weapon',{key:k + 'cs',staticClass:"card-item",attrs:{"item":i},on:{"click":function($event){return _vm.$router.push(("/store/" + (i.market_hash_name)))}}})})],2),_c('div',{staticClass:"px-2 mt-3"},[_c('router-link',{attrs:{"to":"/marketplace"}},[_c('btn-color',{staticClass:"btn-outline-primary w-full",attrs:{"rounded":""}},[_vm._v(_vm._s(_vm.$t('home.explore.see')))])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }