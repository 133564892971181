<template>
  <main>
    <banner />
    <section class="container">
      <section style="margin-top:50px">
        <section-title>
          <div class="d-flex align-items-center">
            {{ $t('home.browse.h') }}
            <drop-down :data="gameTypes" v-model="gameType" primary borderless>
              <template v-slot:all>
                <all-solid-svg class="mr-2" />
              </template>
            </drop-down>
          </div>
        </section-title>

        <!-- <chip-select v-model="chip" :options="options">
          <template v-slot:trending="{ label }">
            <trending />
            <span class="ml-2">{{ label }}</span>
          </template>
          <template v-slot:popular="{ label }">
            <popular />
            <span class="ml-2">{{ label }}</span>
          </template>
          <template v-slot:new="{ label }">
            <new />
            <span class="ml-2">{{ label }}</span>
          </template>
          <template v-slot:onsale="{ label }">
            <on-sale />
            <span class="ml-2">{{ label }}</span>
          </template>
        </chip-select> -->

        <div class="card-box mt-4">
          <collection-card
            v-if="gameType.value === 'all' || gameType.value === 'dota2'"
            class="card-item"
            :item="{
              name: 'DOTA2',
              title: 'DOTA2',
              image_url: 'dota2',
              avatar_url: 'dota2',
            }"
            to="/collections/dota2"
          />
          <collection-card
            v-if="gameType.value === 'all' || gameType.value === 'csgo'"
            class="card-item"
            :item="{
              name: 'CSGO',
              title: 'CSGO',
              image_url: 'csgo',
              avatar_url: 'csgo',
            }"
            to="/collections/csgo"
          />
          <!-- <collection-card class="card-item" v-for="(i, k) in items" :key="k" :item="i" to="/collections/123" /> -->
        </div>
      </section>

      <!-- <section style="margin-top:50px">
        <section-title>
          <div class="d-flex align-items-center">
            {{ $t('home.top.h') }}
            <drop-down :data="optionsDate" v-model="drop2" primary borderless />
          </div>
          <router-link class="ml-auto" to="/collections">
            <btn class="btn-outline-secondary d-flex align-items-center" rounded>
              <span style="padding-top:2px">See All</span>
              <fa class="ml-2" :icon="['fas', 'chevron-right']" />
            </btn>
          </router-link>
          <slide-chip-select class="ml-auto" v-model="drop8" :options="options8" />
        </section-title>

        <my-table :data="tableItems" :loading="tableLoading" :cols="cols" link="/collections/123">
          <template v-slot:col="{ item }">
            <div class="d-flex align-items-center">
              <avatar
                imgUrl="https://lh3.googleusercontent.com/dNMaqYrBRTBWeTQOJsKg0IedkMSS_SB37x1ivbcydSWur1Pv3_sETgQYQdeCgp8Iv4gTT60xz8pnWkfAJF7HwHibOfB94wT5l2WJ2Q=s0"
              />
              <span class="ml-2"> {{ item.col }}</span>
            </div>
          </template>

          <template v-slot:vol="{ item }">
            <div class="d-flex align-items-center">
              <eth-svg width="10" />
              <span class="ml-2"> {{ item.vol }}</span>
            </div>
          </template>

          <template v-slot:rat="{ item }">
            <span class="" :class="[item.rat && item.rat[0] === '-' ? 'text-danger' : 'text-success']">
              {{ item.rat }}</span
            >
          </template>

          <template v-slot:flo="{ item }">
            <div class="d-flex align-items-center">
              <eth-svg width="10" />
              <span class="ml-2"> {{ item.flo }}</span>
            </div>
          </template>
        </my-table>
      </section> -->

      <!-- <section style="margin-top:50px">
        <section-title>
          <div class="d-flex align-items-center">
            Best Sellers in
            <drop-down-borderless
              :data="optionsDate"
              v-model="drop3"
            />
          </div>
        </section-title>
      </section> -->

      <section style="margin-top:50px">
        <section-title>
          <div class="d-flex align-items-center">
            {{ $t('home.explore.h') }}
            <!-- <drop-down class="ml-4" v-model="itemType" :data="itemTypes">
              <template v-slot:title="{ selected }">
                <all-svg />
                <span>{{ selected.label }}</span>
              </template>
              <template v-slot:all>
                <all-solid-svg class="mr-2" />
              </template>
            </drop-down> -->

            <!-- <drop-down-multiple-select-new class="ml-4" multi-select v-model="drop5" :data="options5">
              <template v-slot:title>
                <blockchain-svg class="mr-2" />
                <span>{{ $t('home.explore.menu2.blo') }}</span>
              </template>
            </drop-down-multiple-select-new> -->

            <drop-down-price-range class="ml-4" v-model="priceRange" />
          </div>
          <drop-down class="ml-auto" v-model="priceSort" :data="options7">
            <template v-slot:title="{ selected }">
              <sort-down-svg v-if="priceSort.value === 'htl'" />
              <sort-up-svg v-else />
              {{ selected.label }}
            </template>
          </drop-down>
        </section-title>

        <slide-chip-select-horizontal class="" v-model="itemType" :options="itemTypes" label="品质" />

        <slide-chip-select-horizontal class="" v-model="itemWear" :options="itemWears" label="外观" />

        <div v-if="loading" class="card-box mt-1">
          <div v-for="i in 8" :key="i" class="card-item" style="padding:10px">
            <b-skeleton class="m-0" style="border-radius: 32px;" width="" height="420px" />
          </div>
        </div>

        <div v-else class="card-box mt-1">
          <div
            v-if="filteredItems && filteredItems.length === 0"
            class="d-flex justify-content-center align-items-center text-secondary w-full"
            style="height:300px"
          >
            没有找到相关装备~
          </div>
          <collection-weapon
            class="card-item"
            v-for="(i, k) in filteredItems"
            :key="k + 'cs'"
            :item="i"
            @click="$router.push(`/store/${i.market_hash_name}`)"
          />
          <!-- <nft-card-detailed class="card-item" v-for="(i, k) in filteredItems" :key="k + 'a'" :item="i" /> -->
        </div>

        <div class="px-2 mt-3">
          <router-link to="/marketplace">
            <btn-color class="btn-outline-primary w-full" rounded>{{ $t('home.explore.see') }}</btn-color>
          </router-link>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import Banner from './Banner.vue'
import Trending from '../../components/svgs/Trending.vue'
import Popular from '../../components/svgs/Popular.vue'
import New from '../../components/svgs/New.vue'
import OnSale from '../../components/svgs/OnSale.vue'
import { StoreService } from '@/services/api'

export default {
  name: '',
  components: {
    Banner,
    // Trending,
    // Popular,
    // New,
    // OnSale,
  },
  data() {
    return {
      chip: 'trending',
      loading: false,
      gameType: { label: this.$t('home.explore.menu1.all'), value: 'all' },
      gameTypes: [
        { label: this.$t('home.explore.menu1.all'), value: 'all' },
        { label: 'CS:GO', value: 'csgo' },
        { label: 'DOTA2', value: 'dota2' },
      ],
      drop: { label: this.$t('home.explore.menu1.all'), value: 'all' },
      drop2: { label: this.$t('home.top.menu.1d'), value: 'd' },
      drop3: { label: this.$t('home.top.menu.1d'), value: 'd' },
      drop5: [{ label: 'Ethereum', value: 'e' }],
      priceRange: { type: 'usd', min: '', max: '' },
      priceSort: { label: this.$t('home.explore.menu4.htl'), value: 'htl' },
      options: [
        { label: this.$t('home.browse.chip.tre'), value: 'trending' },
        { label: this.$t('home.browse.chip.pop'), value: 'popular' },
        { label: this.$t('home.browse.chip.new'), value: 'new' },
        { label: this.$t('home.browse.chip.ons'), value: 'onsale' },
      ],
      optionsDate: [
        { label: this.$t('home.top.menu.1d'), value: 'd' },
        { label: this.$t('home.top.menu.7d'), value: 'w' },
        { label: this.$t('home.top.menu.30d'), value: 'm' },
      ],
      itemType: { label: this.$t('home.explore.menu1.all'), value: 'all' },
      itemTypes: [
        { label: this.$t('home.explore.menu1.all'), value: 'all' },
        { label: '消费级', value: '消费级' },
        { label: '军规级', value: '军规级' },
        { label: '工业级', value: '工业级' },
        { label: '匕首', value: '匕首' },
        { label: '受限', value: '受限' },
        { label: '保密', value: '保密' },
        { label: '隐秘', value: '隐秘' },
        { label: '普通级', value: '普通级' },
        { label: '高级', value: '高级' },
        { label: '非凡', value: '非凡' },
        { label: '奇异', value: '奇异' },
        { label: '卓越', value: '卓越' },
        { label: '违禁', value: '违禁' },
      ],
      itemWear: { label: this.$t('home.explore.menu1.all'), value: 'all' },
      itemWears: [
        { label: this.$t('home.explore.menu1.all'), value: 'all' },
        { label: '崭新出厂', value: 'Factory New' },
        { label: '略有磨损', value: 'Minimal Wear' },
        { label: '久经沙场', value: 'Field-Tested' },
        { label: '破损不堪', value: 'Well-Worn' },
        { label: '战痕累累', value: 'Battle-Scarred' },
        // { label: '无涂装', value: 'No Wear' },
      ],
      options5: [
        { label: 'Ethereum', value: 'e' },
        { label: 'Flow', value: 'f' },
        { label: 'Tezos', value: 't' },
        { label: 'Polygon', value: 'pol' },
      ],
      options7: [
        { label: this.$t('home.explore.menu4.htl'), value: 'htl' },
        { label: this.$t('home.explore.menu4.lth'), value: 'lth' },
      ],
      drop8: { label: this.$t('home.top.chip.all'), value: 'All' },
      options8: [
        { label: this.$t('home.top.chip.all'), value: 'All' },
        { label: 'Ethereum', value: 'Ethereum' },
        { label: 'Flow', value: 'Flow' },
        { label: 'Polygon', value: 'Polygon' },
      ],
      tableItems: [
        { col: 'CyberBrokers', vol: '5,234.16', rat: '-123.23%', flo: '8.400', own: '3.7K', sup: '10.0K' },
        { col: 'CyberBrokers', vol: '1,273.23', rat: '+123.23%', flo: '2.500', own: '3.7K', sup: '10.0K' },
        { col: 'CyberBrokers', vol: '8,910.74', rat: '-123.23%', flo: '1.200', own: '3.7K', sup: '10.0K' },
        { col: 'CyberBrokers', vol: '3,878.92', rat: '+123.23%', flo: '6.300', own: '3.7K', sup: '10.0K' },
      ],
      tableLoading: false,
      cols: [
        { label: this.$t('home.top.table.col'), value: 'col' },
        { label: this.$t('home.top.table.vol'), value: 'vol', fixed: '200', sortable: true },
        { label: this.$t('home.top.table.24h'), value: 'rat', fixed: '200' },
        { label: this.$t('home.top.table.flo'), value: 'flo', fixed: '200', sortable: true },
        { label: this.$t('home.top.table.own'), value: 'own', fixed: '150' },
        { label: this.$t('home.top.table.sup'), value: 'sup', fixed: '150' },
      ],
      items: [],
    }
  },
  computed: {
    filteredItems() {
      let rv = this.items
      if (this.priceSort.value === 'htl') {
        rv = rv.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      }
      if (this.priceSort.value === 'lth') {
        rv = rv.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      }
      if (this.priceRange) {
        if (this.priceRange.min) {
          rv = rv.filter(i => parseFloat(i.price) >= this.priceRange.min)
        }
        if (this.priceRange.max) {
          rv = rv.filter(i => parseFloat(i.price) <= this.priceRange.max)
        }
      }
      if (this.itemType && this.itemType.value !== 'all') {
        rv = rv.filter(i => i.type.includes(this.itemType.value))
      }
      if (this.itemWear && this.itemWear.value !== 'all') {
        rv = rv.filter(i => i.market_hash_name.includes(this.itemWear.value))
      }
      return rv
    },
  },
  created() {
    this.loadWeapons()
  },
  methods: {
    async loadWeapons() {
      this.loading = true
      const { data } = await StoreService.getStore()
      const items = data.data.map(i => {
        const rv = i.item
        rv.price = i.price
        return rv
      })
      if (items.length > 8) {
        this.items = items.slice(0, 8)
      } else {
        this.items = items
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
