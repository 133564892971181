<template>
  <section class="banner">
    <div class="banner-bg" :style="{ backgroundImage: `url(${imgSrc[currentSlide]})` }">
      <div class="banner-bg-mask"></div>
    </div>
    <div class="wrapper">
      <div class="text-white col left">
        <h1 class="banner-h1">{{ $t('home.banner.title') }}</h1>
        <h5 class="mt-3" style="font-weight:300;font-size:18px">{{ $t('home.banner.subtitle') }}</h5>
        <div class="mt-5 d-flex">
          <btn-color v-if="!user.isLogin" class="btn-primary mr-3" @click="login">
            {{ $t('home.banner.btn-start') }}
          </btn-color>
          <btn class="btn-secondary" @click="$router.push('/store')">{{ $t('home.banner.btn-browse') }}</btn>
        </div>
        <div v-if="!user.isLogin" class="text-secondary mt-4">
          {{ $t('home.banner.already') }} <a href="/login">{{ $t('home.banner.btn-login') }}</a>
        </div>
      </div>
      <carousel
        v-model="currentSlide"
        :perPage="1"
        autoplay
        loop
        :autoplayTimeout="3000"
        paginationActiveColor="rgba(255, 255, 255, 0.7)"
        paginationColor="rgba(255, 255, 255, 0.2)"
        class="col carousel"
      >
        <carousel-slide v-for="(i, k) in imgSrc" :key="k" :data-index="k" :data-name="k">
          <!-- <img :src="i" style="max-width:100%;" alt="" /> -->
          <div class="carousel-img" :style="`background-image:url(${i})`"></div>
        </carousel-slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { STEAM_LOGIN_URL } from '@/services/config'
import { mapGetters } from 'vuex'

export default {
  name: 'Banner',
  data() {
    return {
      imgSrc: [
        require('@/assets/carousel/1.png'),
        require('@/assets/carousel/2.png'),
        require('@/assets/carousel/3.png'),
        require('@/assets/carousel/4.png'),
        require('@/assets/carousel/5.png'),
      ],
      currentSlide: 0,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    login() {
      window.location = STEAM_LOGIN_URL
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

.banner {
  padding: 150px 50px;
  display: flex;
  justify-content: center;
  position: relative;

  .wrapper {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: center;
    position: relative;

    .left {
      max-width: 50%;
    }

    .col {
    }

    .carousel {
      width: 60%;
      max-width: 800px;
      /* position: absolute;
      left: 50%;
      top: 0; */
    }
  }
}

@include media-breakpoint-down(sm) {
  .banner {
    padding: 80px 30px;

    .banner-h1 {
      font-size: 42px;
    }
    .wrapper {
      flex-direction: column;
      .left {
        max-width: 100%;
      }

      .carousel {
        width: 100%;
        margin-top: 60px;
      }
    }
  }
}

.banner-h1 {
  font-size: 52px;
  text-shadow: 0 0 26px $primary;
}

.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  opacity: 0.4;
  transition: background-image 1s ease;

  .banner-bg-mask {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.carousel-img {
  max-width: 100%;
  height: 200px;
  background-size: cover;
}
</style>
